import React from 'react';

const AboutUsSection = () => {
  return (
    <section id="about" className="bg-white py-12 select-none  scroll-m-10">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-semibold mb-8">Sobre Nós</h2>

        <div className="px-4 md:px-0 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">

          <div className='rounded-md shadow-lg p-6 bg-gray-100 hover:scale-105   duration-300 cursor-pointer'>
            <h3 className="text-xl font-semibold mb-2">Nosso propósito</h3>
            <p className="text-lg text-justify mt-4 mb-4">
              Nosso propósito é ser um refúgio de autoexpressão e confiança, onde cada corte de cabelo é uma oportunidade de realçar a verdadeira identidade de nossos clientes. Acreditamos que a aparência é um reflexo do interior de cada pessoa, e estamos aqui para ajudar nossos clientes a mostrarem quem realmente são, com orgulho.
            </p>
          </div>
          <div className='rounded-md shadow-lg p-6 bg-gray-100 hover:scale-105   duration-300 cursor-pointer'>
            <h3 className="text-xl font-semibold mb-2">Nossa visão</h3>
            <p className="text-lg text-justify mt-4 mb-4">
              Nossa visão é criar um ambiente onde a autenticidade seja celebrada, onde os clientes possam se expressar livremente e onde os estilos ganhem vida. Queremos ser um local onde cada cliente saia com um sorriso no rosto, sentindo-se mais confiante e conectado à sua própria identidade.
            </p>
            <p className="text-lg text-justify mb-4 mt-2">
              Nosso objetivo é ser reconhecidos como a principal barbearia da região, oferecendo serviços de corte e estilo que definem padrões de elegância e sofisticação.
            </p>
          </div>
          <div className='rounded-md shadow-lg p-6 col-span-1 md:col-span-2 lg:col-span-1 bg-gray-100 hover:scale-105   duration-300 cursor-pointer'>
            <h3 className="text-xl font-semibold mb-2">Nossos valores</h3>
            <p className="text-lg text-justify mt-4 mb-4">
              Hoje quero compartilhar com todos vocês os valores que são o alicerce do que fazemos aqui todos os dias. Cada corte de cabelo, cada conversa e cada momento que passamos juntos são guiados por esses princípios que são tão importantes para nós.
            </p>
            <p className="text-lg text-justify mt-4 mb-4">
              <span className='font-semibold'>Qualidade é nossa essência:</span> Acreditamos que cada corte de cabelo é uma oportunidade de criar uma verdadeira obra de arte. Nosso compromisso com a qualidade se reflete em cada detalhe, em cada penteada e em cada olhar satisfeito no espelho.
            </p>
            <p className="text-lg text-justify mt-4 mb-4">
              <span className='font-semibold'>Clientes são nossa prioridade:</span> Nosso atendimento é mais do que apenas técnica, é sobre criar um espaço acolhedor e amigável para cada um de vocês. Cada sorriso que vemos ao sair daqui é uma recompensa que valorizamos profundamente.
            </p>
            <p className="text-lg text-justify mt-4 mb-4">
              <span className='font-semibold'>Estilo é liberdade:</span> Acreditamos que o cabelo é uma forma de expressão pessoal. Aqui, você é livre para escolher um estilo que represente sua individualidade. Queremos que saiam daqui não apenas com um corte, mas com uma confiança renovada.
            </p>
          </div>
        </div>
      </div>
    </section >
  );
};

export default AboutUsSection;
