import "@fontsource/poppins";
import './App.css';
import Navbar from './components/Navbar';
import Main from './components/Main';
import Footer from './components/Footer';
import ServicesSection from './components/SectionServicos';
import AboutUsSection from './components/SectionAbout';
import PhotoGallery from './components/PhotoGallery';
import LocationSection from './components/LocationService';

function App() {
  return (
    <div className="text-black bg-black">
      <Navbar />
      <Main />
      <ServicesSection />
      <PhotoGallery />
      <AboutUsSection />
      <LocationSection />
      <Footer />
    </div>
  );
}

export default App;
