import Gallery from './Galery';

const PhotoGallery = () => {


  return (
    <section id="gallery" className="bg-gray-800 py-12 bg-galery">
      <div className="container mx-auto text-center px-4">
        <h2 className="text-3xl font-semibold text-white mb-12">Galeria</h2>
        <Gallery />
      </div>
    </section>
  );
};

export default PhotoGallery;
