import React from 'react';
import AnimatedComponent from './AnimatedComponent';

const ServicesSection = () => {
  return (
    <section id="services" className="bg-gray-100 py-12 scroll-m-10 select-none">
      <div className="container mx-auto text-center px-4">
        <h2 className="text-3xl font-semibold mb-4">Nossos Serviços</h2>
        <p className="text-gray-600 mb-8">
          Oferecemos uma variedade de serviços de qualidade para atender às suas necessidades.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          <AnimatedComponent>
            <div className="bg-white  hover:scale-105 duration-300 p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-2">Cortes de Cabelo</h3>
              <p className="text-gray-600">
                Nossos barbeiros especializados oferecem cortes de cabelo que combinam estilo e precisão.
              </p>
            </div>
          </AnimatedComponent>
          <AnimatedComponent>
            <div className="bg-white  hover:scale-105 duration-300 p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-2">Tratamentos para Barba</h3>
              <p className="text-gray-600">
                Melhore sua barba com nossos tratamentos relaxantes que mantêm sua barba saudável e brilhante.
              </p>
            </div>
          </AnimatedComponent>
          <AnimatedComponent>
            <div className="bg-white  hover:scale-105 duration-300 p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-2">Estilos Modernos</h3>
              <p className="text-gray-600">
                Fique por dentro das tendências com nossos estilos modernos e inovadores.
              </p>
            </div>
          </AnimatedComponent>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
