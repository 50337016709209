// LocationSection.js
import React from 'react';

const LocationSection = () => {
  return (
    <section id="location" className="bg-gray-100 pt-12 px-0">
      <div className="w-full">
        <h2 className="text-3xl text-center mb-2 font-semibold px-4">Nossa Localização</h2>
        <div className="grid place-content-center w-full mb-8  px-4">
          <span className="text-xl font-bold">R. Antônio Zielonka, 375 - Estância, Pinhais - PR</span>
        </div>
        <div className="relative ">
          <iframe className='w-full h-full min-h-[600px]' title='Dom Jack - Barbearia' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28831.427246234656!2d-49.22877922872149!3d-25.407206999930516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dcef066f1d7a79%3A0xf9e89fa781810768!2sBarbearia%20Dom%20Jack!5e0!3m2!1spt-BR!2sbr!4v1693202888663!5m2!1spt-BR!2sbr" loading="lazy"></iframe>
        </div>
      </div>
    </section>
  );
};

export default LocationSection;
