import React from "react";
import BtnWhatsapp from "./BtnWhatsapp";
import Logo from "./Logo";
import FacebookIcon from "./Icons/Facebook";
import InstagramIcon from "./Icons/Instagram";
import HamburguerIcon from "./Icons/Hamburguer";

function Navbar() {
  const [navbarOpen, setNavbarOpen] = React.useState(false);

  return (
    <header className={"fixed bg-black text-white top-0 w-full clearNav z-50 shadow-xl grid place-content-center" + (
      navbarOpen ? "h-screen pb-4" : "h-20 md:h-auto"
    )}>
      <div className="w-full lg:max-w-6xl mx-auto flex flex-wrap p-2 flex-col md:flex-row">
        <div className="w-full lg:w-auto flex flex-row items-center justify-between">
          <a
            href="/"
            className="grid place-content-center text-3xl text-white font-medium align-middle items-center"
          >
            <Logo mode="logoGrande2" alt="Logo - Dom Barber" />
          </a>
          <div className="block lg:hidden"><BtnWhatsapp /></div>
          <button
            className="text-white pb-4 w-min cursor-pointer leading-none px-3 py-1 lg:hidden outline-none focus:outline-none content-end"
            type="button"
            aria-label="button"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <HamburguerIcon className="feather feather-menu w-6 fill-white stroke-white" />
          </button>
        </div>
        <div
          className={
            "lg:flex flex-grow items-center mt-4 md:mt-0" +
            (navbarOpen ? " flex" : " hidden")
          }
        >
          <div className="md:ml-auto md:mr-auto font-4 pt-1 md:pl-14 pl-1 flex flex-wrap items-center md:text-base text-1xl md:justify-center justify-items-start">
            <a href="#home" className="mr-11 pr-2 cursor-pointer text-white hover:text-gray-300 hover:scale-105 font-semibold tr04 w-full md:w-auto mb-4 md:mb-0">
              Início
            </a>
            <a href="#services" className="mr-11 pr-2 cursor-pointer text-white hover:text-gray-300 hover:scale-105 font-semibold tr04 w-full md:w-auto mb-4 md:mb-0">
              Nossos serviços
            </a>
            <a href="#about" className="mr-11 pr-2 cursor-pointer text-white hover:text-gray-300 hover:scale-105 font-semibold tr04 w-full md:w-auto mb-4 md:mb-0">
              Sobre nós
            </a>
            <a href="#location" className="mr-11 pr-2 cursor-pointer text-white hover:text-gray-300 hover:scale-105 font-semibold tr04 w-full md:w-auto mb-4 md:mb-0">
              Localização
            </a>
            <div className="hidden md:block pr-4"><BtnWhatsapp /></div>
            <FacebookIcon className="w-8 h-8 inline-block mr-4 fill-white hover:fill-blue-500 hover:scale-105 cursor-pointer" />
            <InstagramIcon className="w-8 h-8 inline-block mr-4 fill-white hover:fill-orange-500 hover:scale-105 cursor-pointer" />
          </div>
        </div>
      </div>
    </header>)
}

export default Navbar;