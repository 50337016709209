import { useInView } from 'react-intersection-observer';

const AnimatedComponent = (props: any, { duration }: { duration?: number }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const time = duration ? `duration-[${duration}]` : 'duration-1000';

  return (
    <div ref={ref} className={`transition-opacity ${time} ${inView ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-10'}`}>
      {props.children}
    </div>
  );
};

export default AnimatedComponent;
