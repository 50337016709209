
export type logoStyle = {
  mode: string;
  alt: 'Logo - Dom Barber'
}

export default function Logo(params: logoStyle) {

  const logos: any = {
    light: "./../assets/img/logo-light.png",
    dark: "./../assets/img/logo.png",
    alt1: "./../assets/img/logo-1.jpeg",
    alt2: "./../assets/img/logo-2.jpeg",
    alt3: "./../assets/img/logo-3.jpeg",
    logoGrande: "./../assets/img/logo-grande.jpeg",
    logoGrande2: "./../assets/img/logo-grande-2.jpg",
  }

  const sizes: any = {
    light: "w-20 xl:w-36 block",
    dark: "w-20 xl:w-36 block",
    alt1: "w-20 xl:w-36 block",
    alt2: "w-20 block",
    alt3: "w-20 block",
    logoGrande: "w-20 xl:w-36 block",
    logoGrande2: "w-20 xl:w-36 block",
  }


  return (
    <img className={`${sizes[params.mode]}`} src={logos[params.mode]} alt={params.alt} />
  )
}