import React, { useState } from 'react';
import AnimatedComponent from './AnimatedComponent';

const Gallery = () => {
  const [showMore, setShowMore] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState<any>(null);

  const openModal = (item: any) => {
    setSelectedMedia(item);
    setModalVisible(true);
  };

  const closeModal = () => {
    setSelectedMedia(null);
    setModalVisible(false);
  };
  const squareMidias = [
    {
      'src': '/assets/galeria/1.mp4',
      'alt': 'Vídeo 1',
      'type': 'video'
    },
    {
      'src': '/assets/galeria/2.mp4',
      'alt': 'Vídeo 2',
      'type': 'video'
    },
    {
      'src': '/assets/galeria/3.mp4',
      'alt': 'Vídeo 3',
      'type': 'video'
    },
    {
      'src': '/assets/galeria/5.mp4',
      'alt': 'Vídeo 5',
      'type': 'video'
    },
    {
      'src': '/assets/galeria/1.jpeg',
      'alt': 'Foto 1',
      'type': 'image'
    },
    {
      'src': '/assets/galeria/5.jpeg',
      'alt': 'Foto 5',
      'type': 'image'
    },
    {
      'src': '/assets/galeria/2.jpeg',
      'alt': 'Foto 2',
      'type': 'image'
    },
    {
      'src': '/assets/galeria/4.jpeg',
      'alt': 'Foto 4',
      'type': 'image'
    },
    {
      'src': '/assets/galeria/6.jpeg',
      'alt': 'Foto 6',
      'type': 'image'
    },
    {
      'src': '/assets/galeria/7.jpeg',
      'alt': 'Foto 7',
      'type': 'image'
    },

    {
      'src': '/assets/galeria/9.jpeg',
      'alt': 'Foto 9',
      'type': 'image'
    },
    {
      'src': '/assets/galeria/11.jpeg',
      'alt': 'Foto 11',
      'type': 'image'
    },
    {
      'src': '/assets/galeria/13.jpeg',
      'alt': 'Foto 13',
      'type': 'image'
    },
    {
      'src': '/assets/galeria/14.jpeg',
      'alt': 'Foto 14',
      'type': 'image'
    },
    {
      'src': '/assets/galeria/15.jpeg',
      'alt': 'Foto 15',
      'type': 'image'
    },

    {
      'src': '/assets/galeria/17.jpeg',
      'alt': 'Foto 17',
      'type': 'image'
    },
    {
      'src': '/assets/galeria/19.jpeg',
      'alt': 'Foto 19',
      'type': 'image'
    },
    {
      'src': '/assets/galeria/20.jpeg',
      'alt': 'Foto 20',
      'type': 'image'
    },
    {
      'src': '/assets/galeria/21.jpeg',
      'alt': 'Foto 21',
      'type': 'image'
    },
    {
      'src': '/assets/galeria/22.jpeg',
      'alt': 'Foto 22',
      'type': 'image'
    },
    {
      'src': '/assets/galeria/23.jpeg',
      'alt': 'Foto 23',
      'type': 'image'
    },
    {
      'src': '/assets/galeria/25.jpeg',
      'alt': 'Foto 25',
      'type': 'image'
    },
    {
      'src': '/assets/galeria/26.jpeg',
      'alt': 'Foto 26',
      'type': 'image'
    },
    {
      'src': '/assets/galeria/27.jpeg',
      'alt': 'Foto 27',
      'type': 'image'
    },
    {
      'src': '/assets/galeria/8.jpg',
      'alt': 'Foto 8',
      'type': 'image'
    },
  ]

  const portraitMidias = [
    {
      'src': '/assets/galeria/4.mp4',
      'alt': 'Vídeo 4',
      'type': 'video'
    },
    {
      'src': '/assets/galeria/3.jpeg',
      'alt': 'Foto 3',
      'type': 'image'
    },
    {
      'src': '/assets/galeria/18.jpeg',
      'alt': 'Foto 18',
      'type': 'image'
    },

    {
      'src': '/assets/galeria/24.jpeg',
      'alt': 'Foto 24',
      'type': 'image'
    },
    {
      'src': '/assets/galeria/10.jpeg',
      'alt': 'Foto 10',
      'type': 'image'
    },

    // {
    //   'src': '/assets/galeria/12.jpeg',
    //   'alt': 'Foto 12',
    //   'type': 'image'
    // },
    {
      'src': '/assets/galeria/16.jpg',
      'alt': 'Foto 16',
      'type': 'image'
    },
  ];

  const photos = [
    {
      src: '/assets/img/barbearia-2.png',
      alt: 'Foto 1',
      type: 'image'
    },
    {
      src: '/assets/img/barbearia-3.png',
      alt: 'Foto 2',
      type: 'image'
    },
  ];

  return (
    <>
      <AnimatedComponent>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {photos.map((photo, index) => (

            <div key={index} onClick={() => openModal(photo)} className="rounded-lg overflow-hidden hover:shadow-xl hover:scale-[101%] transition duration-300 relative">

              <img
                src={photo.src}
                alt={photo.alt}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition duration-300">

              </div>
            </div>

          ))}
        </div>
      </AnimatedComponent>
      <div className="mt-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {portraitMidias.map((item, index) => (
            <div key={index} onClick={() => openModal(item)} className="relative overflow-hidden rounded-lg shadow-lg transition transform hover:scale-105 duration-300">
              {item.type === 'image' && (
                <img src={item.src} alt={item.alt} className="w-full h-auto" />
              )}
              {item.type === 'video' && (
                <video controls className="w-full h-auto">
                  <source src={item.src} type="video/mp4" />
                  Seu navegador não suporta a reprodução de vídeo.
                </video>
              )}
            </div>
          ))}
        </div>

        {showMore && <AnimatedComponent> <div className="grid mt-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {squareMidias.map((item, index) => (
            <div key={index} onClick={() => openModal(item)} className="relative overflow-hidden rounded-lg shadow-lg transition transform hover:scale-105 duration-300">
              {item.type === 'image' && (
                <img src={item.src} alt={item.alt} className="w-full h-auto" />
              )}
              {item.type === 'video' && (
                <video controls className="w-full h-auto">
                  <source src={item.src} type="video/mp4" />
                  Seu navegador não suporta a reprodução de vídeo.
                </video>
              )}
            </div>
          ))}
        </div>
        </AnimatedComponent>}

        <div className='grid place-content-center mt-8 mb-4'>
          <button onClick={() => setShowMore(!showMore)} className='rounded-md bg-white text-black hover:shadow-2xl hover:scale-105 duration-300 shadow-lg text-base text-center px-4 py-2'>
            {showMore ? 'Mostar menos' : 'Mostrar mais'}
          </button>
        </div>

        {modalVisible && selectedMedia && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-75">
            <div className="max-w-xl p-4 bg-white rounded-lg shadow-lg overflow-hidden">
              {selectedMedia.type === 'image' && (
                <img src={selectedMedia.src} alt={selectedMedia.alt} className="w-full h-auto" />
              )}
              {selectedMedia.type === 'video' && (
                <video controls className="w-full">
                  <source src={selectedMedia.src} type="video/mp4" />
                  Seu navegador não suporta a reprodução de vídeo.
                </video>
              )}
              <button className="mt-2 bg-black text-white py-2 px-4 rounded-md" onClick={closeModal}>
                Fechar
              </button>
            </div>
          </div>
        )}
      </div>
    </>);
};

export default Gallery;
