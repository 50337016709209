import Lottie from 'lottie-react';
import ScrollLottie from './../assets/lottie/scroll.json'
import AnimatedComponent from './AnimatedComponent';
import BtnWhatsapp from './BtnWhatsapp';

export default function Main() {
  console.log('Made with ❤ by https://github.com/djpfs')
  return (
    <section id="home" className="text-gray-600 body-font bg-white  home-section pb-10">
      <div className="max-w-5xl pt-20 md:pt-32 lg:pt-52 pb-24 mx-auto min-h-screen px-4 md:px-0 relative">
        <div className="w-full grid place-content-center">
          <img alt='Logo' src="./assets/img/logo-light.png" className="h-36 lg:h-56 xl:h-72 mb-4" />
        </div>
        <AnimatedComponent>
          <h2 className="text-xl md:text-3xl font-4 font-semibold lh-6 ld-04 pb-11 text-white text-center">
            Descubra a essência da elegância masculina na Barbearia Dom Jack. Cortes, barba e estilo que refletem sua autenticidade. Agende sua transformação hoje mesmo!
          </h2></AnimatedComponent>
        <div className="text-center">
          <AnimatedComponent>
            <BtnWhatsapp />
          </AnimatedComponent>
          <div className="w-full grid place-content-center">
            <Lottie style={{ width: '200px' }} animationData={ScrollLottie} loop={true} autoPlay={true} />
          </div>
        </div>

      </div>
    </section >
  );
}