import FacebookIcon from "./Icons/Facebook";
import InstagramIcon from "./Icons/Instagram";
import Logo from "./Logo";
import MadeWithLove from "./MadeWithLove";

const Footer = () => {

  const year = new Date().getFullYear();

  return (
    <footer className="bg-black text-white py-4 h-auto md:h-auto">
      <div className="w-full px-4 md:px-20 mx-auto flex flex-wrap justify-between items-center">
        <p className="text-sm md:text-base w-4/12">&copy; {year} Dom Jack</p>
        <Logo mode="light" alt="Logo - Dom Barber" />
        <ul className="w-4/12 flex justify-end space-x-4">
          <li>
            <FacebookIcon className="w-8 h-8 inline-block mr-4 fill-white hover:fill-blue-500 hover:scale-105 cursor-pointer" />
          </li>
          <li>
            <InstagramIcon className="w-8 h-8 inline-block mr-4 fill-white hover:fill-orange-500 hover:scale-105 cursor-pointer" />
          </li>
        </ul>
      </div>

      {/* <div className="grid place-content-center">
        <MadeWithLove />
      </div> */}
    </footer>
  );
};

export default Footer;
